import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Typeform, Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Find Your Idea intro";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "welcome-to-find-your-idea"
    }}>{`Welcome to Find Your idea`}</h1>
    <p>{`Hi, welcome to the Find Your Idea self-paced workshop`}</p>
    <p>{`This workshop is a series of exercises that walk you through a framework for finding, validating, and qualifying ideas so you never have to worry about working on the wrong thing.`}</p>
    <p>{`It works like this:`}</p>
    <ol>
      <li parentName="ol">{`Watch a video that sets the scene`}</li>
      <li parentName="ol">{`Try to solve the problem`}</li>
      <li parentName="ol">{`See my solution and explanation of how I got there`}</li>
    </ol>
    <p>{`But first, why did you buy this workshop?`}</p>
    <Typeform url="https://swizecteller.typeform.com/to/V2UNlk" mdxType="Typeform" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      